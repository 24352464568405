<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          v-model="bundleAspek.jenjang"
          outlined
          label="jenjang"
          :options="$LIST_JENJANG"
          @input="getSubAspek(), getlastformat()"
        ></q-select>
        <q-select
          outlined
          v-if="bundleAspek.jenjang == 'MA'"
          v-model="bundleAspek.tingkat"
          label="Nama Penilaian"
          :options="tingkat_ma"
          @input="getSubAspek(), getlastformat()"
        ></q-select>
        <q-select
          outlined
          v-else
          v-model="bundleAspek.tingkat"
          label="Nama Penilaian"
          :options="tingkat_smp"
          @input="getSubAspek(), getlastformat()"
        ></q-select>
        <q-select
          v-model="bundleAspek.id_aspek_shalat"
          outlined
          :options="subAspek"
          label="Aspek Shalat"
        ></q-select>
        <q-select
          v-model="bundleAspek.id_aspek_tilawah"
          outlined
          :options="subAspek"
          label="Aspek Tilawah"
        ></q-select>
        <p class="q-mb-none q-pl-md text-grey-7">Batas Nilai</p>
        <div class="row justify-between q-pt-none">
          <q-input
            outlined
            type="number"
            v-model="bundleAspek.batas_a"
            label="A"
            dense
            style="width:24%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleAspek.batas_b"
            label="B"
            dense
            style="width:24%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleAspek.batas_c"
            label="C"
            dense
            style="width:24%"
          ></q-input>
          <q-input
            outlined
            readonly
            type="number"
            value="0"
            label="D"
            dense
            style="width:24%"
          ></q-input>
          <a class="text-caption text-red q-pl-sm" style="width:100%"
            >*batas bawah pada nilai kualitatif</a
          >
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          color="primary"
          @click="save"
          :disable="
            bundleAspek.id_aspek_shalat == null ||
              bundleAspek.id_aspek_tilawah == null
          "
          >Simpan</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["jenjang", "tingkat"],
  data() {
    return {
      tingkat_smp: ["7", "8", "9"],
      tingkat_ma: ["10", "11", "12"],
      subAspek: [],
      bundleAspek: {
        jenjang: "SMP PA",
        tingkat: "7",
        id_aspek_shalat: null,
        id_aspek_tilawah: null,
        batas_a: 75,
        batas_b: 50,
        batas_c: 25,
        batas_d: 0,
      },
    };
  },
  async mounted() {
    this.bundleAspek.jenjang = this.jenjang;
    this.bundleAspek.tingkat = this.tingkat;
    await this.getSubAspek();
    await this.getlastformat();
  },
  methods: {
    async save() {
      this.bundleAspek.id_aspek_shalat = this.bundleAspek.id_aspek_shalat.value;
      this.bundleAspek.id_aspek_tilawah = this.bundleAspek.id_aspek_tilawah.value;
      await this.$http.post(`/hasil/laporan/savenew`, this.bundleAspek);
      this.onOKClick();
    },
    async getSubAspek() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/hasil/laporan/getsubaspek/${this.bundleAspek.jenjang}`
      );
      this.subAspek = resp.data;
      this.$q.loading.hide();
    },
    async getlastformat() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let jenjang = this.bundleAspek.jenjang;
      let tingkat = this.bundleAspek.tingkat;
      this.bundleAspek = {
        jenjang: jenjang,
        tingkat: tingkat,
        id_aspek_shalat: null,
        id_aspek_tilawah: null,
        batas_a: 75,
        batas_b: 50,
        batas_c: 25,
        batas_d: 0,
      };

      let resp = await this.$http.get(
        `/hasil/laporan/getlatest/${this.bundleAspek.jenjang}/${this.bundleAspek.tingkat}`
      );
      if (resp.data.length > 0) {
        this.bundleAspek = resp.data[0];
        this.bundleAspek.id_aspek_shalat = await this.subAspek.find(
          ({ value }) => value == this.bundleAspek.id_aspek_shalat
        );
        this.bundleAspek.id_aspek_tilawah = await this.subAspek.find(
          ({ value }) => value == this.bundleAspek.id_aspek_tilawah
        );
      }
      this.$q.loading.hide();
    },
    changeTingkat() {
      if (this.bundleAspek.jenjang == "MA") {
        this.bundleAspek.tingkat = "10";
      } else {
        this.bundleAspek.tingkat = "7";
      }
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {};
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
