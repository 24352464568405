<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section horizontal>
        <q-card-section
          class="column q-gutter-md justify-between"
          style="min-width:300px"
        >
          <div>
            <q-select
              outlined
              label="Jenjang"
              :options="$LIST_JENJANG"
              v-model="selJenjang"
              @input="getKelasInJenjang"
            />
            <q-select
              outlined
              class="q-mt-md"
              label="Kelas"
              :options="listKelas"
              v-model="selKelas"
              @input="getdata"
            />
          </div>

          <q-btn unelevated color="positive" label="Publish"></q-btn>
        </q-card-section>
        <q-card-section class="q-pa-none full-width">
          <p
            class="q-py-sm q-mb-none bg-positive text-center"
            v-if="selKelas == null"
          >
            <a>
              Silahkan Pilih Kelas Terlebih Dahulu
            </a>
          </p>
          <p
            class="row items-center justify-around q-py-sm q-mb-none bg-positive"
            v-else-if="kualitatif == null"
          >
            <a>
              Belum ada Nilai Kualitatif, mohon isi terlebih dahulu dengen
              menekan tombol "ubah"
            </a>
            <q-btn flat size="sm" label="Ubah" @click="showDialogEdit"></q-btn>
          </p>
          <p
            class="row items-center justify-around q-py-sm q-mb-none bg-positive"
            v-else
          >
            <a>
              Range Nilai:
            </a>
            <a> A: 100 - {{ kualitatif.batas_a }} </a>
            <a> B: {{ kualitatif.batas_a - 1 }} - {{ kualitatif.batas_b }} </a>
            <a> C: {{ kualitatif.batas_b - 1 }} - {{ kualitatif.batas_c }} </a>
            <a> D: {{ kualitatif.batas_c - 1 }}-0 </a>
            <q-btn flat size="sm" label="Ubah" @click="showDialogEdit"></q-btn>
          </p>

          <q-markup-table
            flat
            bordered
            wrap-cells
            dense
            separator="horizontal"
            class="stickyTable bg-indigo-1 full-width bg-grey-4"
            style="height: calc(100vh - 240px);"
          >
            <thead class="bg-indigo-5 text-white text-left">
              <tr>
                <th>no</th>
                <th>NIS</th>
                <th>Nama</th>
                <th>Shalat</th>
                <th>Tilawah</th>
                <th>Rata-Rata</th>
                <th>Range</th>
              </tr>
            </thead>
            <tbody class="bg-white" v-if="kualitatif != null">
              <tr v-for="(el, i) in listSiswa" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ el.nisn }}</td>
                <td>{{ el.nama }}</td>
                <td v-if="el.nilai_shalat">{{ el.nilai_shalat }}</td>
                <td v-if="el.nilai_tilawah">{{ el.nilai_tilawah }}</td>
                <td v-if="el.rerata">{{ el.rerata }}</td>
                <td v-if="el.range">{{ el.range }}</td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import DialogPengaturanKualitatif from "@/components/DialogPengaturanKualitatif";
export default {
  data() {
    return {
      searchTerm: "",
      listSiswa: [],
      listKelas: [],
      selKelas: null,
      selJenjang: "SMP PA",
      kualitatif: null
    };
  },
  /* computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter(val => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    }
  }, */
  async mounted() {
    await this.getKelasInJenjang();
  },
  methods: {
    async getNilai() {
      let temp = JSON.parse(JSON.stringify(this.listSiswa));
      let resp = await this.$http.post(
        `/hasil/laporan/getnilai/${this.kualitatif.id_aspek_shalat}/${
          this.kualitatif.id_aspek_tilawah
        }/${localStorage.getItem("id_tahun_ajaran")}`,
        temp
      );
      for (let i in resp.data) {
        if (
          resp.data[i].shalat[0] &&
          resp.data[i].shalat[0].nilai_musyrif &&
          resp.data[i].tilawah[0] &&
          resp.data[i].tilawah[0].nilai_musyrif
        ) {
          temp[i].nilai_shalat = parseFloat(
            (parseFloat(resp.data[i].shalat[0].nilai_musyrif) +
              parseFloat(resp.data[i].shalat[0].nilai_pekan)) /
              2
          ).toFixed(2);
          temp[i].nilai_tilawah = parseFloat(
            (parseFloat(resp.data[i].tilawah[0].nilai_musyrif) +
              parseFloat(resp.data[i].tilawah[0].nilai_pekan)) /
              2
          ).toFixed(2);
          temp[i].rerata = parseFloat(
            (parseFloat(temp[i].nilai_shalat) +
              parseFloat(temp[i].nilai_tilawah)) /
              2
          ).toFixed(2);
          if (
            temp[i].rerata <= 100 ||
            temp[i].rerata >= this.kualitatif.batas_a
          ) {
            temp[i].range = "A";
          } else if (
            temp[i].rerata < this.kualitatif.batas_a ||
            temp[i].rerata >= this.kualitatif.batas_b
          ) {
            temp[i].range = "B";
          } else if (
            temp[i].rerata < this.kualitatif.batas_b ||
            temp[i].rerata >= this.kualitatif.batas_c
          ) {
            temp[i].range = "C";
          } else if (
            temp[i].rerata < this.kualitatif.batas_c ||
            temp[i].rerata >= 0
          ) {
            temp[i].range = "D";
          }
        }
      }
      this.listSiswa = temp;
    },
    async getdata() {
      await this.getkualitatif();
      await this.getSiswa();
    },
    async getSiswa() {
      let resp = await this.$http.get(
        `/hasil/laporan/getsiswa/${this.selKelas.value}`
      );
      this.listSiswa = resp.data;
      if (this.kualitatif) {
        this.getNilai();
      }
    },
    async getkualitatif() {
      this.kualitatif = null;
      let resp = await this.$http.get(
        `/hasil/laporan/getlatest/${this.selJenjang}/${this.selKelas.tingkat}`
      );
      if (resp.data.length > 0) {
        this.kualitatif = resp.data[0];
      }
    },
    async getKelasInJenjang() {
      let resp = await this.$http.get(
        `/hasil/laporan/getkelas/${this.selJenjang}`
      );
      this.listKelas = resp.data;
    },
    showDialogEdit() {
      this.$q
        .dialog({
          component: DialogPengaturanKualitatif,
          parent: this,
          jenjang: this.selJenjang,
          tingkat: this.selKelas.tingkat
        })
        .onOk(resp => {});
    }
  }
};
</script>

<style lang="scss" scoped></style>
